<template>
  <div v-if="!isLoading" class="maintain-table">
    <div class="user-card">
      <div class="user-avatar">
        <img :src="`${$PicPrefix}${caseInfo['userInfo'].photo}`" alt="" />
      </div>
      <div class="user-info">
        <div class="user-name">{{ caseInfo.userName }}</div>
        <div class="case-no">
          {{ $t("cases.cases.blh") }}：{{ caseInfo.caseNumber }}
        </div>
        <div class="clinical-situation">
          {{ $t("cases.cases.lcqk") }}: {{ caseInfo.clinicInfo }}
        </div>
      </div>
    </div>
    <div v-if="cureProblemList.length > 0" class="dia_problem">
      <div class="flex-y-c mb10">
        <img
          alt=""
          class="mr10"
          src="../../../../../common/imgs/case/dia_warn_cion.png"
          style="width: 24px"
        />
        <h5>{{ $t("cases.createImage.czrxwt") }}</h5>
      </div>
      <div class="pro_box">
        <span
          v-for="(item, index) in cureProblemList"
          :key="`${item.id}_${index}`"
          class="curp"
        >
          {{ item.groupNo }} {{ item.groupName }}: {{ item.content }}
        </span>
      </div>
    </div>
    <div id="treatment" class="case-question">
      <div class="case-title required-title">
        1.{{ $t("cases.maintain.jzwcqk") }}
      </div>
      <div class="c-q-content">
        <div class="subtitle required-title">
          1.1 {{ $t("cases.maintain.qrwcsynzqk") }}
        </div>
        <div class="flex-y-c">
          <CommonButton
            :echoContent="resultObj['treatmentStatus']"
            :renderList="caseOptions['treatment_status']"
            parentKey="treatmentStatus"
            @buttonChange="buttonHandlerChange"
          />
          <div class="need-tips">{{ $t("cases.maintain.qzsrkntxqtyy") }}</div>
        </div>
        <div
          v-show="resultObj['treatmentStatus'] === 'other'"
          class="has-other-input"
        >
          <el-input
            v-model="otherValue"
            :autosize="{ minRows: 4, maxRows: 6 }"
            :placeholder="$t('cases.maintain.qyqkxzz')"
            class="other-textarea"
            maxlength="2000"
            show-word-limit
            type="textarea"
          ></el-input>
        </div>
        <div class="subtitle required-title">
          1.2{{ $t("cases.maintain.jzwcrq") }}
        </div>
        <el-date-picker
          v-model="dateValue"
          :placeholder="$t('cases.maintain.xzrq')"
          type="date"
          value-format="yyyy-MM-dd"
          @change="changeDateValue"
        >
        </el-date-picker>
      </div>
    </div>
    <div id="retainer" class="case-question">
      <div class="case-title required-title">
        2.{{ $t("cases.maintain.dzbcq") }}
      </div>
      <div class="c-q-content">
        <div class="flex-y-c">
          <CommonButton
            :echoContent="resultObj['retainer']"
            :renderList="caseOptions['need']"
            parentKey="retainer"
            @buttonChange="buttonHandlerChange"
          />
          <div class="need-tips">{{ $t("cases.maintain.sdblwczlh") }}</div>
        </div>
        <div v-show="resultObj['retainer'] === 'true'">
          <div class="subtitle required-title">
            2.1 {{ $t("cases.maintain.dzfs") }}
          </div>
          <CommonButton
            :echoContent="resultObj['alignerBasedOn']"
            :renderList="caseOptions['aligner_based_on']"
            parentKey="alignerBasedOn"
            @buttonChange="buttonHandlerChange"
          />
          <div class="subtitle required-title">
            2.2 {{ $t("cases.maintain.czxz") }}
          </div>
          <el-select
            v-model="plasticsSheetValue"
            :placeholder="$t('cases.maintain.qxzcz')"
            @change="changePlasticsSheet"
          >
            <el-option
              v-for="item in plasticsSheetList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <div class="subtitle required-title">
            2.3 {{ $t("cases.maintain.sfmcfj") }}
          </div>
          <CommonButton
            :echoContent="resultObj['removeAttachment']"
            :renderList="caseOptions['true_false']"
            parentKey="removeAttachment"
            @buttonChange="buttonHandlerChange"
          />

          <div class="subtitle">2.4 {{ $t("cases.maintain.sfxyccmxqg") }}</div>
          <CommonButton
            :echoContent="resultObj['aestheticCutting']"
            :renderList="caseOptions['true_false']"
            parentKey="aestheticCutting"
            @buttonChange="buttonHandlerChange"
          />

          <div class="subtitle">2.5 {{ $t("cases.maintain.scssfxyylwz") }}</div>
          <CommonButton
            :echoContent="resultObj['reservedPosition']"
            :renderList="caseOptions['reserved_position']"
            parentKey="reservedPosition"
            @buttonChange="buttonHandlerChange"
          />

          <div class="subtitle">
            2.6 {{ $t("cases.maintain.shscsfxyfzyhdb") }}
          </div>
          <CommonButton
            :echoContent="resultObj['occlusalGuide']"
            :renderList="caseOptions['true_false']"
            parentKey="occlusalGuide"
            @buttonChange="buttonHandlerChange"
          />
        </div>
      </div>
    </div>
    <div class="case-question">
      <div class="case-title">
        3.{{ $t("cases.maintain.jzwchmxjknzp") }}
        <div class="link">
          <img
            alt=""
            class="dib mr4"
            src="../../../../../common/imgs/case/creater_img_ask.png"
            style="width: 12px"
          />
          <router-link
            :to="{ name: 'helpCenter', query: { type: '12' } }"
            style="display: flex; align-items: center"
            target="_blank"
          >
            <span class="main_theme_color fz14 curp">{{
              $t("cases.createImage.zppsbz")
            }}</span>
          </router-link>
        </div>
      </div>
      <div class="c-q-content">
        <div class="upload-tips">{{ $t("cases.maintain.ktztp") }}</div>
        <div class="upload-img-container">
          <div
            v-for="(item, index) in facePic"
            :key="index"
            class="upload-img-item"
          >
            <div
              v-if="resultObj[item.id] && resultObj[item.id].nanoId"
              class="after-upload"
              @dragover.prevent
              @drop.prevent="
                event => {
                  handleDrop(
                    event,
                    item.id,
                    $t(`cases.createImage.${item.label}`)
                  );
                }
              "
              @click="
                showCarousel({
                  filed: item.id,
                  text: item.name,
                  index: getPicIndex(item.id)
                })
              "
            >
              <img
                v-if="resultObj[item.id] && resultObj[item.id].nanoId"
                :src="
                  `${$PicPrefix}${
                    resultObj[item.id].nanoId
                  }?imageView2/0/w/360/h/250`
                "
                :style="{
                  transform: `rotate(${
                    resultObj[item.id]['degree']
                  }deg) scaleY(${
                    resultObj[item.id]['vertical'] ? -1 : 1
                  }) scaleX(${resultObj[item.id]['horizontal'] ? -1 : 1})`
                }"
                alt=""
                class="after-upload-img"
              />
              <span
                v-show="resultObj[item.id] && resultObj[item.id].nanoId"
                class="remove_pic"
                @click="() => removeFiledPic(item.id)"
              ></span>
              <p class="pic_look">
                <img alt="" src="/static/images/createCases/check_icon.png" />
                <span>{{ $t("cases.createImage.ck") }}</span>
              </p>
            </div>
            <div v-else class="before-upload">
              <el-upload
                :action="UPLOAD_URL"
                :before-upload="beforeUpload"
                :data="{ token: uploadToken, key: nanoid }"
                :on-error="uploadError"
                :on-success="
                  res =>
                    uploadSuccess(
                      res,
                      item.id,
                      $t(`cases.createImage.${item.label}`)
                    )
                "
                :show-file-list="false"
                accept="image/jpeg,image/bmp,image/png"
                class="upload-content"
                drag
                list-type="picture"
              >
                <div class="up_box">
                  <p class="up_box_tip">
                    <span class="up___tip_icon"></span
                    >{{ $t("cases.createImage.sctz") }}
                  </p>
                </div>
                <p :class="`up_load_def up_load_def${index + 1}`"></p>
              </el-upload>
            </div>
            <div class="img-name">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="case-question">
      <div class="case-title">
        4.{{ $t("cases.maintain.jzwchxgp") }}
        <div class="link">
          <img
            alt=""
            class="dib mr4"
            src="../../../../../common/imgs/case/creater_img_ask.png"
            style="width: 12px"
          />
          <router-link
            :to="{ name: 'helpCenter', query: { type: '13' } }"
            style="display: flex; align-items: center"
            target="_blank"
          >
            <span class="fz14 main_theme_color">{{
              $t("cases.createImage.xgpbz")
            }}</span>
          </router-link>
        </div>
      </div>
      <div class="c-q-content">
        <div class="upload-tips">{{ $t("cases.maintain.ktztp") }}</div>
        <div class="upload-img-container">
          <div
            v-for="(item, index) in XRayPic"
            :key="index"
            class="upload-img-item"
          >
            <div
              v-if="resultObj[item.id] && resultObj[item.id].nanoId"
              class="after-upload"
              @dragover.prevent
              @drop.prevent="
                event => {
                  handleDrop(
                    event,
                    item.id,
                    $t(`cases.createImage.${item.label}`)
                  );
                }
              "
              @click="
                showCarousel({
                  filed: item.id,
                  text: item.name,
                  index: getPicIndex(item.id)
                })
              "
            >
              <img
                v-if="resultObj[item.id] && resultObj[item.id].nanoId"
                :src="
                  `${$PicPrefix}${
                    resultObj[item.id].nanoId
                  }?imageView2/0/w/360/h/250`
                "
                :style="{
                  transform: `rotate(${
                    resultObj[item.id]['degree']
                  }deg) scaleY(${
                    resultObj[item.id]['vertical'] ? -1 : 1
                  }) scaleX(${resultObj[item.id]['horizontal'] ? -1 : 1})`
                }"
                alt=""
                class="after-upload-img"
              />
              <span
                v-show="resultObj[item.id] && resultObj[item.id].nanoId"
                class="remove_pic"
                @click="() => removeFiledPic(item.id)"
              ></span>
              <p class="pic_look">
                <img alt="" src="/static/images/createCases/check_icon.png" />
                <span>{{ $t("cases.createImage.ck") }}</span>
              </p>
            </div>
            <div v-else class="before-upload">
              <el-upload
                :action="UPLOAD_URL"
                :before-upload="beforeUpload"
                :data="{ token: uploadToken, key: nanoid }"
                :on-error="uploadError"
                :on-success="
                  res =>
                    uploadSuccess(
                      res,
                      item.id,
                      $t(`cases.createImage.${item.label}`)
                    )
                "
                :show-file-list="false"
                accept="image/jpeg,image/bmp,image/png"
                class="upload-content"
                drag
                list-type="picture"
              >
                <div class="up_box">
                  <p class="up_box_tip">
                    <span class="up___tip_icon"></span
                    >{{ $t("cases.createImage.sctz") }}
                  </p>
                </div>
                <p class="up_load_def up_load_def10"></p>
              </el-upload>
            </div>
            <div class="img-name">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div id="dentalModel" class="case-question">
      <div class="case-title required-title">
        5.{{ $t("cases.maintain.yhmx") }}
      </div>
      <div class="c-q-content">
        <div class="flex-y-c">
          <CommonButton
            :disabled="dentalModelDisable"
            :echoContent="resultObj['dentalModel']"
            :renderList="caseOptions['submit']"
            parentKey="dentalModel"
            @buttonChange="buttonHandlerChange"
          />
          <div class="model-tips">{{ $t("common.common.shxh") }}</div>
        </div>
        <div v-if="resultObj['dentalModel'] === 'submit'">
          <div class="button-filed-item">
            <div
              :style="{ width: language == 'zh_CN' ? '1.0rem' : '1.4rem' }"
              class="label"
            >
              {{ $t("cases.createImage.gxjmx") }}
            </div>
            <div class="filed-value">
              <CommonButton
                :echoContent="resultObj['siliconeImpression']"
                :renderList="siliconeRubberModel"
                parentKey="siliconeImpression"
                @buttonChange="buttonHandlerChange"
              />
            </div>
          </div>
          <div
            v-if="resultObj['siliconeImpression'] === 'silicagel_print_model'"
            class="print-model"
          >
            <div class="tips">
              {{ $t("cases.createImage.yscblh") }}
            </div>
            <div class="send-express-container">
              <div class="case-number">
                {{ $t("cases.cases.blh") }}：
                {{ caseInfo.caseNumber }}
              </div>
              <div ref="copyCases" class="copy" @click="copyCasesNumber"></div>
              <div class="send" @click="sendExpress">
                <img
                  alt=""
                  class="express-icon"
                  src="/static/images/createCases/pc_doc_shunfeng.png"
                />
                {{ $t("cases.createImage.fkd") }}
              </div>
            </div>
          </div>
          <div class="button-filed-item">
            <div
              :style="{ width: language == 'zh_CN' ? '1.0rem' : '1.4rem' }"
              class="label"
            >
              {{ $t("cases.createImage.szmxwz") }}
            </div>
            <div class="filed-value">
              <CommonButton
                :echoContent="resultObj['stlFile']"
                :renderList="digitalModelFile"
                parentKey="stlFile"
                @buttonChange="buttonHandlerChange"
              />
            </div>
          </div>
          <div
            v-show="resultObj['stlFile'] === 'local_upload'"
            :style="{ marginLeft: language === 'zh_CN' ? '1.2rem' : '1.6rem' }"
          >
            <div class="s_c__up">
              {{ $t("cases.createImage.se") }}
              <el-upload
                ref="sharpUpJaw"
                :action="UPLOAD_URL"
                :before-upload="
                  file => beforeFileUpload(file, 'sharp_upjaw_remain', 'stl')
                "
                :data="{ token: uploadToken }"
                class="upload-file"
                drag
              >
                <span class="up_load">{{ $t("cases.createImage.scstl") }}</span>
              </el-upload>
              <!-- <span
                class="up_icon"
                @click="clickSharpUpJaw('sharpUpJaw')"
              ></span> -->
              <p v-show="fileNameObj['sharp_upjaw_remain']" class="up_file">
                {{ fileNameObj["sharp_upjaw_remain"] }}
                <span class="up_status el-icon-success"></span>
              </p>
            </div>
            <div class="s_c__up">
              {{ $t("cases.createImage.xe") }}
              <el-upload
                ref="sharpDownJaw"
                :action="UPLOAD_URL"
                :before-upload="
                  file => beforeFileUpload(file, 'sharp_downjaw_remain', 'stl')
                "
                :data="{ token: uploadToken }"
                class="upload-file"
                drag
              >
                <span class="up_load">{{ $t("cases.createImage.scstl") }}</span>
              </el-upload>
              <!-- <span
                class="up_icon"
                @click="clickSharpUpJaw('sharpDownJaw')"
              ></span> -->
              <p v-show="fileNameObj['sharp_downjaw_remain']" class="up_file">
                {{ fileNameObj["sharp_downjaw_remain"] }}
                <span class="up_status el-icon-success"></span>
              </p>
            </div>
          </div>
          <div
            v-show="resultObj['stlFile'] === 'iTero'"
            :style="{ marginLeft: language === 'zh_CN' ? '1.2rem' : '1.6rem' }"
          >
            <div class="s_c__up">
              {{ $t("cases.createImage.wj") }}
              <el-upload
                ref="iTero"
                :action="UPLOAD_URL"
                :before-upload="file => beforeFileUpload(file, 'iTero', 'zip')"
                :data="{ token: uploadToken }"
                class="upload-file"
                drag
              >
                <span class="up_load">{{ $t("cases.createImage.sczip") }}</span>
              </el-upload>
              <!-- <span class="up_icon" @click="clickSharpUpJaw('iTero')"></span> -->
              <p v-show="fileNameObj['iTero_remain']" class="up_file">
                {{ fileNameObj["iTero_remain"] }}
                <span class="up_status el-icon-success"></span>
              </p>
            </div>
          </div>
          <div
            v-show="resultObj['stlFile'] === 'other'"
            :style="{ marginLeft: language === 'zh_CN' ? '1.2rem' : '1.6rem' }"
          >
            <div class="s_c__up">
              {{ $t("cases.createImage.wj") }}
              <el-upload
                ref="otherFile"
                :action="UPLOAD_URL"
                :before-upload="
                  file =>
                    beforeFileUpload(file, 'other_file_remain', 'zip', 'other')
                "
                :data="{ token: uploadToken }"
                :file-list="fileNameObj['other_file_remain']"
                :limit="10"
                :on-exceed="otherListInit"
                :show-file-list="false"
                class="upload-file"
                drag
                multiple
              >
                <span class="up_load">{{ $t("cases.createImage.sczip") }}</span>
              </el-upload>
              <!-- <span
                class="up_icon"
                @click="clickSharpUpJaw('otherFile')"
              ></span> -->
              <ul
                v-show="
                  fileNameObj['other_file_remain'] &&
                    fileNameObj['other_file_remain'].length
                "
              >
                <li
                  v-for="(item, index) in fileNameObj['other_file_remain']"
                  :key="index"
                  class="up_file other_file"
                >
                  {{ item.name }}
                  <span class="up_status el-icon-success"></span>
                  <span
                    class="up_status el-icon-close curp"
                    @click="deleteOtherFile(index, item.id)"
                  ></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <FullLoading v-show="uploading" />

    <ExBirdie
      ref="exBirdie"
      :caseDetail="caseInfo"
      :defaultKey="exDefaultKey"
      caseType="2"
      refType="3"
      @tabsChange="tabsChanged"
    />

    <PreviewImg
      ref="previewImg"
      :carouselList="carouselList"
      @imgChange="imgChange"
    />
  </div>
</template>

<script>
import { getQiniuToken } from "common/api/public";
import { caseDoneProblem, caseDoneDetail } from "common/api/cases";
import CommonButton from "./common-button.vue";
import { nanoid } from "nanoid";
import { UPLOAD_URL } from "common/js/config";
import * as QINIU from "qiniu-js";
import { notifyMsg } from "common/js/util";
import ExBirdie from "components/expressage_birdie/index";
import PreviewImg from "components/preview-image/preview-image.vue";
import { filter, map, extend } from "lodash";
import { mapState, mapActions } from "vuex";
import FullLoading from "components/full-loading/full-loading";

export default {
  components: {
    CommonButton,
    PreviewImg,
    ExBirdie,
    FullLoading
  },
  computed: {
    ...mapState({
      language: state => state.language
    })
  },
  props: {
    questionName: {
      required: true
    },
    diaKeyObj: {
      required: true
    },
    caseOptions: {
      required: true
    },
    plasticsSheetList: {
      required: true
    },
    caseInfo: {
      required: false
    }
  },
  data() {
    return {
      dateValue: "",
      otherValue: "",
      dentalModelDisable: false,
      uploadToken: "",
      UPLOAD_URL,
      nanoid: nanoid(),
      carouselList: [],
      isLoading: true,
      uploading: false,
      plasticsSheetValue: "",
      hasDeliver: false,
      curePlanInfo: {},
      cureProblemList: [],
      exDefaultKey: "",
      resultObj: {
        treatmentStatus: "",
        treatmentStatusOther: "",
        treatmentCompletionDate: "",
        retainer: "",
        alignerBasedOn: "",
        materialSelection: "",
        removeAttachment: "",
        aestheticCutting: "",
        reservedPosition: "",
        occlusalGuide: "",
        sideProfile: "",
        frontal: "",
        frontalSmile: "",
        upperArch: "",
        anteriorOverjet: "",
        lowerArch: "",
        intraOralRight: "",
        intraOralFrontal: "",
        intraOralLeft: "",
        panoramicRadiograph: "",
        lateralCephalogram: "",
        dentalModel: "",
        siliconeImpression: "",
        stlFile: "",
        attachmentList: []
      },
      idxObj: {
        sideProfile: 0,
        frontal: 1,
        frontalSmile: 2,
        upperArch: 3,
        anteriorOverjet: 4,
        lowerArch: 5,
        intraOralRight: 6,
        intraOralFrontal: 7,
        intraOralLeft: 8,
        panoramicRadiograph: 9,
        lateralCephalogram: 10
      },
      fileNameObj: {
        other_file_remain: [],
        iTero_remain: "",
        sharp_upjaw_remain: "",
        sharp_downjaw_remain: ""
      },
      facePic: [
        {
          id: "sideProfile",
          name: this.$t("cases.createImage.cmx"),
          label: "cmx"
        },
        {
          id: "frontal",
          name: this.$t("cases.createImage.zmx"),
          label: "zmx"
        },
        {
          id: "frontalSmile",
          name: this.$t("cases.createImage.wxx"),
          label: "wxx"
        },
        {
          id: "upperArch",
          name: this.$t("cases.createImage.sylx"),
          label: "sylx"
        },
        {
          id: "anteriorOverjet",
          name: this.$t("cases.createImage.qyfhfgx"),
          label: "qyfhfgx"
        },
        {
          id: "lowerArch",
          name: this.$t("cases.createImage.xylx"),
          label: "xylx"
        },
        {
          id: "intraOralRight",
          name: this.$t("cases.createImage.knzcyx"),
          label: "knzcyx"
        },
        {
          id: "intraOralFrontal",
          name: this.$t("cases.createImage.knzwx"),
          label: "knzwx"
        },
        {
          id: "intraOralLeft",
          name: this.$t("cases.createImage.knycyx"),
          label: "knycyx"
        }
      ],
      XRayPic: [
        {
          id: "panoramicRadiograph",
          name: this.$t("cases.createImage.dcp"),
          label: "dcp"
        },
        {
          id: "lateralCephalogram",
          name: this.$t("cases.createImage.dwp"),
          label: "dwp"
        }
      ],
      siliconeRubberModel: [
        {
          key: "silicagel_print_model",
          value: "上门取件",
          enValue: "The door to take"
        }
      ],
      digitalModelFile: [
        {
          key: "local_upload",
          value: "本地上传",
          enValue: "Upload"
        },
        {
          key: "iTero",
          value: "iTero",
          enValue: "iTero"
        },
        {
          key: "other",
          value: "其他",
          enValue: "Others"
        }
      ]
    };
  },
  mounted() {
    getQiniuToken().then(data => {
      this.uploadToken = data;
    });
    this.getCureProblemList();
    this.getCurePlanDetail();
  },
  methods: {
    ...mapActions({
      getCaseCureDetail: "actionGetCaseCureDetail" //治疗表详情
    }),
    handleDrop(event, filed, name) {
      const file = event.dataTransfer.files[0];
      this.nanoid = nanoid();
      const supportedFormats = ["image/jpeg", "image/png", "image/bmp"];
      let isLt20M = file.size / 1024 / 1024 < 20;
      if (!supportedFormats.includes(file.type)) {
        notifyMsg(this, "warning", this.$t("common.message.supportedFormats"));
        return false;
      } else if (!isLt20M) {
        notifyMsg(this, "warning", this.$t("common.message.isLt20M"));
        return false;
      } else {
        // this.isLoading = true;
        this.beforeDrop(file, filed, name);
      }
    },
    async beforeDrop(file, filed, name) {
      const params = await new Promise((resolve, reject) => {
        const formData = new FormData();
        // 在form实例中添加 file qiniuToekn 固定格式类名
        formData.append("file", file);
        formData.append("token", this.uploadToken);
        formData.append("key", nanoid());
        const fileName = file.name.replace(/\s/g, ""); // 去除空格
        const ts = new Date().getTime();
        const xhr = new XMLHttpRequest();
        xhr.open("POST", "https://upload.qiniup.com/", true);
        // 携带登录token
        xhr.setRequestHeader("Authorization", localStorage.getItem("token"));

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            // 根据组件 api 返回固定格式的 url
            const response = JSON.parse(xhr.responseText);
            // const alt = response.key;
            resolve(response);
          } else if (xhr.readyState === 4 && xhr.status !== 200) {
            reject("上传失败"); // 如果上传失败，返回错误信息
          }
        };
        xhr.send(formData);
      });
      this.carouselList = this.carouselList.filter(v => v?.filed !== filed);
      this.uploadSuccess(params, filed, name);
    },
    otherListInit() {
      notifyMsg(this, "warning", this.$t("cases.createImage.zdxz"));
    },
    getCureProblemList() {
      caseDoneProblem({
        caseId: this.$route.query.caseId,
        curePlanId: this.$route.query.curePlanId
      }).then(res => {
        this.cureProblemList = res;
      });
    },
    getCurePlanDetail() {
      caseDoneDetail({
        refType: "completion",
        refCode: this.$route.query.curePlanId
      })
        .then(res => {
          this.isLoading = false;
          this.curePlanInfo = res;
          this.$router.push({
            query: {
              ...this.$route.query,
              // productType: this.caseInfo["cureType"]
            }
          });
          if (
            this.caseInfo.hasOwnProperty("selfDeliverFlag") &&
            this.caseInfo.selfDeliverFlag == "1"
          ) {
            this.hasDeliver = true;
          }
          for (const echoKey in this.resultObj) {
            for (const resultKey in res) {
              if (echoKey === resultKey) {
                this.resultObj[echoKey] = res[resultKey];
              }
            }
          }
          this.resultObj["sideProfile"] = this.initObj(
            this.resultObj["sideProfile"],
            "sideProfile",
            this.$t("cases.createImage.cmx")
          );
          this.resultObj["frontal"] = this.initObj(
            this.resultObj["frontal"],
            "frontal",
            this.$t("cases.createImage.zmx")
          );
          this.resultObj["frontalSmile"] = this.initObj(
            this.resultObj["frontalSmile"],
            "frontalSmile",
            this.$t("cases.createImage.wxx")
          );
          this.resultObj["upperArch"] = this.initObj(
            this.resultObj["upperArch"],
            "upperArch",
            this.$t("cases.createImage.sylx")
          );
          this.resultObj["anteriorOverjet"] = this.initObj(
            this.resultObj["anteriorOverjet"],
            "anteriorOverjet",
            this.$t("cases.createImage.qyfhfgx")
          );
          this.resultObj["lowerArch"] = this.initObj(
            this.resultObj["lowerArch"],
            "lowerArch",
            this.$t("cases.createImage.xylx")
          );
          this.resultObj["intraOralRight"] = this.initObj(
            this.resultObj["intraOralRight"],
            "intraOralRight",
            this.$t("cases.createImage.knzcyx")
          );
          this.resultObj["intraOralFrontal"] = this.initObj(
            this.resultObj["intraOralFrontal"],
            "intraOralFrontal",
            this.$t("cases.createImage.knzwx")
          );
          this.resultObj["intraOralLeft"] = this.initObj(
            this.resultObj["intraOralLeft"],
            "intraOralLeft",
            this.$t("cases.createImage.knycyx")
          );
          this.resultObj["panoramicRadiograph"] = this.initObj(
            this.resultObj["panoramicRadiograph"],
            "panoramicRadiograph",
            this.$t("cases.createImage.dcp")
          );
          this.resultObj["lateralCephalogram"] = this.initObj(
            this.resultObj["lateralCephalogram"],
            "lateralCephalogram",
            this.$t("cases.createImage.dwp")
          );
          this.plasticsSheetList.map(item => {
            if (item.id === this.resultObj["materialSelection"]) {
              this.plasticsSheetValue = item.name;
            }
          });
          this.init();
          const fixNameObj = {
            1: "treatment",
            2: "retainer",
            3: "dentalModel"
          };
          if (this.questionName) {
            document
              .querySelector(`#${fixNameObj[this.questionName]}`)
              .scrollIntoView({
                behavior: "smooth"
              });
          }
        })
        .catch(() => (this.isLoading = false));
    },
    initObj(str, filed, name) {
      if (str == "") {
        return "";
      }
      let obj = JSON.parse(str);
      !obj.nanoId && (obj.nanoId = obj.ETag);
      !obj.degree && (obj.degree = 0);
      !obj.horizontal && (obj.horizontal = false);
      !obj.vertical && (obj.vertical = false);
      obj.filed = filed;
      obj.name = name;
      return obj;
    },
    imgChange(obj) {
      this.$forceUpdate();
      for (const key in this.resultObj) {
        if (obj.filed === key) {
          const current = this.resultObj[key];
          this.$set(current, "degree", obj.degree);
          this.$set(current, "horizontal", obj.horizontal);
          this.$set(current, "vertical", obj.vertical);
        }
      }
    },
    showCarousel(item) {
      this.$nextTick(() => {
        this.$refs["previewImg"] &&
          this.$refs["previewImg"].show(item.text, item.index);
      });
    },
    deleteOtherFile(index, id) {
      this.fileNameObj["other_file_remain"].splice(index, 1);
      this.resultObj.attachmentInfoList = filter(
        map(this.resultObj.attachmentInfoList, item => {
          if (item.id === id) {
            return;
          }
          return item;
        })
      );
    },
    getPicIndex(filed) {
      return filter(this.carouselList, item => item.filed === filed)[0].index;
    },
    copyCasesNumber() {
      navigator.clipboard.writeText(this.caseInfo.caseNumber);
      notifyMsg(this, "success", this.$t("cases.createImage.fzcg"));
    },
    stagingData() {
      const result = {
        ...this.resultObj,
        treatmentStatusOther: this.otherValue
      };
      return result;
    },
    sendExpress() {
      this.exDefaultKey = "silicagel_print_model";
      this.$refs["exBirdie"].openDia().then(() => {
        this.$refs["exBirdie"] &&
          this.$refs["exBirdie"].resetClinic(this.caseInfo.clinicId);
        this.$emit("stagingData");
      });
    },
    tabsChanged(key) {
      this.exDefaultKey = key;
      this.resultObj["siliconeImpression"] = key;
    },
    removeFiledPic(filed) {
      const { resultObj } = this;
      this.resultObj = {
        ...resultObj,
        [filed]: ""
      };
      this.carouselList = this.carouselList.filter(
        item => item.filed !== filed
      );
      this.carouselList.map((item, index) => {
        this.carouselList[index].index = index + "";
      });
    },
    beforeFileUpload(file, key, type) {
      const { name } = file;
      const _this = this;
      const filedTypeFilter = type === "zip" ? ["zip", "rar"] : ["stl"];
      const fileType = name?.split(".").pop();
      if (filedTypeFilter.includes(fileType)) {
        this.uploading = true;
        const { attachmentInfoList } = _this.resultObj;
        const list =
          key !== "other_file_remain"
            ? attachmentInfoList.filter(item => item.fileKey !== key)
            : attachmentInfoList;
        const aBlob = new Blob([file]);
        const ts = new Date().getTime();
        const observable = QINIU.upload(
          aBlob,
          ts + "_" + name,
          this.uploadToken
        );
        observable.subscribe({
          error(err) {
            _this.uploading = false;
          },
          complete(res) {
            const { hash } = res;
            _this.uploading = false;
            _this.resultObj.attachmentInfoList = [
              ...list,
              {
                fileKey: key === "iTero" ? "iTero_remain" : key,
                fileName: ts + "_" + name,
                realFileName: name,
                fileType: type,
                fileUrl: hash,
                fileSource: "DOCTOR"
              }
            ];
            if (key === "other_file_remain") {
              if (!_this.fileNameObj[key]) {
                _this.$set(_this.fileNameObj, key, [{ name: ts + "_" + name }]);
              } else {
                _this.$set(_this.fileNameObj, key, [
                  ..._this.fileNameObj[key],
                  { name: ts + "_" + name }
                ]);
              }
            } else {
              _this.fileNameObj[key === "iTero" ? "iTero_remain" : key] =
                ts + "_" + name;
            }
            notifyMsg(_this, "success", _this.$t("common.message.wjsccg"));
          }
        });
      } else {
        notifyMsg(
          this,
          "warning",
          `${this.$t("common.message.qxz")}${type}${this.$t(
            "common.message.wjsc"
          )}`
        );
      }
      return false;
    },
    uploadSuccess(res, filed, name, file, type) {
      this.$forceUpdate();
      this.uploading = false;
      const { resultObj } = this;
      resultObj[filed] = { ETag: res.key, nanoId: res.key };
      let index = this.carouselList.length;
      this.carouselList.splice(
        this.$route.query.productType == "F"
          ? this.yysdIdxObj[filed]
          : this.idxObj[filed],
        0,
        {
          nanoId: res.key,
          name,
          ETag: res.key,
          href: this.$PicPrefix + res.hash,
          filed
        }
      );
      this.carouselList.map((item, index) => {
        this.carouselList[index].index = index + "";
      });
      notifyMsg(this, "success", this.$t("common.message.tpsccg"));
    },
    beforeUpload(file) {
      this.nanoid = nanoid();
      const supportedFormats = ["image/jpeg", "image/png", "image/bmp"];
      let isLt20M = file.size / 1024 / 1024 < 20;
      if (!supportedFormats.includes(file.type)) {
        notifyMsg(this, "warning", this.$t("common.message.supportedFormats"));
        return false;
      } else if (!isLt20M) {
        notifyMsg(this, "warning", this.$t("common.message.isLt20M"));
        return false;
      } else {
        return true;
      }
    },
    uploadError() {
      return notifyMsg(this, "error", this.$t("common.message.scsb"));
    },
    buttonHandlerChange(val, parentKey) {
      this.$forceUpdate();
      if (parentKey === "siliconeImpression") {
        (this.fileNameObj = {
          other_file_remain: [],
          iTero_remain: "",
          sharp_upjaw_remain: "",
          sharp_downjaw_remain: ""
        }),
          this.$delete(this.resultObj, "stlFile");
      }
      if (parentKey === "stlFile") {
        this.$delete(this.resultObj, "siliconeImpression");
      }
      if (this.resultObj["stlFile"] !== val["stlFile"]) {
        this.fileNameObj = {
          other_file_remian: [],
          iTero_remain: "",
          sharp_upjaw_remian: "",
          sharp_downjaw_remain: ""
        };
        this.$set(this.resultObj, "attachmentInfoList", []);
      }
      this.resultObj = Object.assign(this.resultObj, val);
      if (val["siliconeImpression"] === "self_deliver") {
        this.exDefaultKey = "self_deliver";
        this.$refs["exBirdie"].openDia().then(() => {
          this.$refs["exBirdie"] &&
            this.$refs["exBirdie"].resetClinic(this.caseInfo.clinicId);
          this.$emit("stagingData");
        });
      }
      if (this.resultObj["retainer"]) {
        if (this.resultObj["retainer"] === "true") {
          this.dentalModelDisable = false;
          this.$set(this.resultObj, "dentalModel", "");
          if (this.resultObj["alignerBasedOn"] === "provided_impression") {
            this.dentalModelDisable = true;
            this.$set(this.resultObj, "dentalModel", "submit");
          }
          if (this.resultObj["alignerBasedOn"] === "last_step") {
            this.fileNameObj = {
              other_file_remian: [],
              iTero_remain: "",
              sharp_upjaw_remian: "",
              sharp_downjaw_remain: ""
            };
            this.$set(this.resultObj, "dentalModel", "cancel");
            this.$delete(this.resultObj, "siliconeImpression");
            this.dentalModelDisable = true;
          }
        } else {
          this.fileNameObj = {
            other_file_remian: [],
            iTero_remain: "",
            sharp_upjaw_remian: "",
            sharp_downjaw_remain: ""
          };
          this.$set(this.resultObj, "dentalModel", "cancel");
          this.$delete(this.resultObj, "stlFile");
          this.$delete(this.resultObj, "alignerBasedOn");
          this.$delete(this.resultObj, "materialSelection");
          this.$delete(this.resultObj, "removeAttachment");
          this.$delete(this.resultObj, "aestheticCutting");
          this.$delete(this.resultObj, "reservedPosition");
          this.$delete(this.resultObj, "occlusalGuide");
          this.$delete(this.resultObj, "siliconeImpression");
          this.plasticsSheetValue = "";
          this.dentalModelDisable = true;
        }
      }
    },
    changeDateValue(val) {
      this.$forceUpdate();
      this.resultObj["treatmentCompletionDate"] = val;
    },
    changePlasticsSheet(val) {
      this.resultObj["materialSelection"] = val;
    },
    init() {
      this.dateValue = this.resultObj["treatmentCompletionDate"];
      this.otherValue = this.resultObj["treatmentStatusOther"];
      if (this.hasDeliver) {
        this.siliconeRubberModel = [
          ...this.siliconeRubberModel,
          {
            key: "self_deliver",
            value: "自行邮寄",
            enValue: "By mail"
          }
        ];
      }
      const { attachmentList } = this.resultObj;

      let subList = filter(attachmentList, item =>
        [
          "sharp_upjaw_remain",
          "sharp_downjaw_remain",
          "iTero_remain",
          "other_file_remain"
        ].includes(item.fileKey)
      );
      this.resultObj = extend({}, this.resultObj, {
        attachmentInfoList: subList
      });
      const fileObj = {};
      attachmentList.forEach(item => {
        const obj = {
          name: item.fileName,
          url: `${this.$PicPrefix}${item.fileUrl}`
        };
        if (item.fileKey !== "other_file_remain") {
          this.fileNameObj[item.fileKey] = item.fileName;
        } else {
          this.fileNameObj["other_file_remain"].push({
            name: item.fileName
          });
        }
        if (!fileObj[item.fileKey]) {
          fileObj[item.fileKey] = [obj];
        } else {
          fileObj[item.fileKey].push(obj);
        }
      });
      let picList = [
        this.resultObj["sideProfile"],
        this.resultObj["frontal"],
        this.resultObj["frontalSmile"],
        this.resultObj["upperArch"],
        this.resultObj["anteriorOverjet"],
        this.resultObj["lowerArch"],
        this.resultObj["intraOralRight"],
        this.resultObj["intraOralFrontal"],
        this.resultObj["intraOralLeft"],
        this.resultObj["panoramicRadiograph"],
        this.resultObj["lateralCephalogram"]
      ];
      picList = picList.filter(item => item.nanoId);
      this.carouselList = map(picList, (item, index) => {
        return extend({}, item, { index: index + "" });
      });
      if (this.resultObj["retainer"]) {
        if (this.resultObj["retainer"] === "true") {
          this.dentalModelDisable = false;
          if (this.resultObj["alignerBasedOn"] === "provided_impression") {
            this.dentalModelDisable = true;
            this.$set(this.resultObj, "dentalModel", "submit");
          }
          if (this.resultObj["alignerBasedOn"] === "last_step") {
            this.dentalModelDisable = true;
            this.$set(this.resultObj, "dentalModel", "cancel");
          }
        } else {
          this.dentalModelDisable = true;
          this.$set(this.resultObj, "dentalModel", "cancel");
          this.$delete(this.resultObj, "alignerBasedOn");
          this.$delete(this.resultObj, "materialSelection");
          this.$delete(this.resultObj, "removeAttachment");
          this.$delete(this.resultObj, "aestheticCutting");
          this.$delete(this.resultObj, "reservedPosition");
          this.$delete(this.resultObj, "occlusalGuide");
          this.$delete(this.resultObj, "plasticsSheetValue");
          this.plasticsSheetValue = "";
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.maintain-table {
  padding: 10px 30px 100px;

  .dia_problem {
    width: 100%;
    background: #f4f4f4;
    padding: 10px 16px 30px;
    transform: translateY(30px);
    margin-bottom: 50px;

    h5 {
      font-size: 0.22rem;
      color: $main_theme_color_333;
    }

    span {
      margin-right: 0.2rem;
    }

    .pro_box {
      word-break: break-all;
      font-size: 0.16rem;
      line-height: 0.3rem;
      color: $main_theme_color_333;
    }
  }

  .cure-problem {
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    .problem-item {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 10px;
      color: #ed4027;
      word-break: break-all;
    }
  }

  .user-card {
    height: 180px;
    border-radius: 6px;
    padding: 30px;
    background-color: #f4f4f4;
    display: flex;
    align-items: center;

    .user-avatar {
      margin-right: 20px;

      img {
        width: 120px;
        height: 120px;
        border-radius: 50%;
      }
    }

    .user-info {
      font-size: 14px;
      line-height: 20px;
      color: #333;

      .user-name {
        font-size: 20px;
        line-height: 28px;
      }

      .case-no {
        margin: 4px 0 10px;
      }

      .clinical-situation {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }

  .case-title {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 26px;
    color: #333;
    margin: 20px 0;

    .link {
      display: flex;
      align-items: center;
      color: #fcc80e;
      margin-left: 10px;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .required-title {
    display: flex;
    align-items: center;

    &::after {
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #ed4027;
      margin-left: 4px;
    }
  }

  .c-q-content {
    padding-left: 30px;

    .need-tips {
      margin-bottom: 20px;
      color: #999;
      font-size: 14px;
      line-height: 20px;
      margin-left: 10px;
    }

    .model-tips {
      margin-bottom: 20px;
      color: #ed4027;
      font-size: 14px;
      line-height: 20px;
      margin-left: 10px;
    }

    .subtitle {
      font-size: 16px;
      line-height: 20px;
      color: #333;
      margin: 10px 0 20px;
    }

    .has-other-input {
      padding-right: 290px;
    }
  }

  .button-filed-item {
    display: flex;
    align-items: center;

    .label {
      margin-bottom: 20px;
      margin-right: 20px;
      white-space: nowrap;
    }
  }

  .print-model {
    margin-bottom: 30px;

    .tips {
      font-size: 14px;
      line-height: 20px;
      color: #666;
      margin-bottom: 10px;
    }
  }

  .send-express-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 436px;
    height: 68px;
    border-radius: 4px;
    background-color: #f4f4f4;
    padding: 16px 20px;
    color: #333;
    font-size: 14px;
    line-height: 20px;

    .copy {
      cursor: pointer;
      width: 24px;
      height: 24px;
      background: url("../../../../../common/imgs/case/case_icon_plus.png")
        no-repeat center;
    }

    .send {
      .express-icon {
        width: 24px;
        height: 24px;
        margin-right: 4px;
      }

      cursor: pointer;
      padding: 0 12px;
      height: 36px;
      border-radius: 4px;
      background-color: #fcc80e;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
    }
  }

  .upload-tips {
    font-size: 14px;
    line-height: 20px;
    color: #333;
    margin-bottom: 10px;
  }

  .up_load_def {
    position: absolute;
    left: 0;
    top: 0.5rem;
    right: 0;
    height: 1rem;
    z-index: 9;
    background-size: 100% 100%;
  }

  .s_c__up {
    display: flex;
    align-items: center;
    font-weight: normal;
    margin-bottom: 0.16rem;
    font-size: 14px;
    color: $main_theme_color_333;

    .upload-file {
      margin: 0 0.2rem;
    }

    .up_load {
      display: inline-block;
      height: 0.4rem;
      line-height: 0.4rem;
      margin: 0 0.16rem;
      border-radius: 0.03rem;
      outline: none;
      padding: 0 0.2rem;
      position: relative;
    }

    .up_icon {
      display: inline-block;
      width: 0.24rem;
      height: 0.24rem;
      background-image: url("/static/images/createCases/web_doc_upload.png");
      background-size: 100% 100%;
      cursor: pointer;
    }

    .up_status {
      color: #5cb6ff;
    }

    .up_file {
      display: flex;
      align-items: center;
      margin-left: 0.2rem;

      .up_status {
        color: #5cb6ff;
        margin-left: 0.1rem;
      }
    }

    .other_file {
      margin-bottom: 0.1rem;
    }
  }

  .up_load_def1 {
    background-image: url("/static/images/createCases/side.png");
  }

  .up_load_def2 {
    background-image: url("/static/images/createCases/front.png");
  }

  .up_load_def3 {
    background-image: url("/static/images/createCases/smile.png");
  }

  .up_load_def4 {
    background-image: url("/static/images/createCases/top.png");
  }

  .up_load_def5 {
    background-image: url("/static/images/createCases/qyfgx.png");
    background-repeat: no-repeat;
  }

  .up_load_def6 {
    background-image: url("/static/images/createCases/bottom.png");
  }

  .up_load_def7 {
    background-image: url("/static/images/createCases/right.png");
  }

  .up_load_def8 {
    background-image: url("/static/images/createCases/main.png");
  }

  .up_load_def9 {
    background-image: url("/static/images/createCases/left.png");
  }

  .up_load_def10 {
    background-image: url("/static/images/createCases/select.png");
    background-repeat: no-repeat;
  }

  .up_load_def11 {
    width: 92px;
    height: 52px;
    left: 50%;
    top: 82px;
    transform: translateX(-50%);
    background-image: url("/static/images/createCases/yhx.png");
    background-repeat: no-repeat;
  }

  .up_load_def12 {
    width: 74px;
    height: 67px;
    left: 50%;
    top: 73px;
    transform: translateX(-50%);
    background-image: url("/static/images/createCases/khx.png");
    background-repeat: no-repeat;
  }

  .upload-img-container {
    display: flex;
    flex-wrap: wrap;

    .upload-img-item {
      width: 344px;
      height: 280px;
      margin: 0 46px 30px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &:nth-child(3n + 3) {
        margin-right: 0px;
      }

      .after-upload {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 240px;
        //border: 1px solid #bbb;
        border-radius: 8px;
        background-color: #f0f0f0;
        position: relative;
        overflow: hidden;

        &:hover .pic_look {
          opacity: 1;
        }

        .remove_pic {
          position: absolute;
          right: 0.1rem;
          top: 0.1rem;
          width: 0.3rem;
          height: 0.3rem;
          z-index: 9;
          cursor: pointer;
          border-radius: 100%;
          background-size: 100% 100%;
          background-image: url("/static/images/createCases/delet_img.png");
        }

        img {
          max-width: 100%;
          max-height: 100%;
        }

        .pic_look {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 0.5rem;
          background-color: rgba(0, 0, 0, 0.6);
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.16rem;
          opacity: 0;
          transition: opacity 0.6s;
          z-index: 1;

          img {
            width: 0.24rem;
            height: 0.16rem;
            margin-right: 0.1rem;
          }
        }
      }

      .before-upload {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 240px;
        border: 1px solid #bbb;
        border-radius: 8px;
        background-color: #f0f0f0;

        .up_box {
          width: 3.44rem;
          height: 240px;
          position: relative;
          background: #f0f0f0;

          .up_box_tip {
            width: 100%;
            position: absolute;
            bottom: 0.26rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            color: $main_theme_color_333;

            .up___tip_icon {
              display: inline-block;
              width: 0.25rem;
              height: 0.25rem;
              background-size: 100% 100%;
              background-image: url("/static/images/createCases/add_upload_img.png");
              border-radius: 100%;
              margin-right: 0.1rem;
            }
          }
        }
      }

      .img-name {
        font-size: 16px;
        line-height: 22px;
        color: #333;
        text-align: center;
        margin-top: 10px;
      }
    }
  }
}

/deep/ .el-dialog {
  position: static;
}
</style>
