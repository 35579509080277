<template>
  <div
    :class="alignType === 'row' ? 'button-content' : 'button-content-column'"
  >
    <div class="button-wrap" v-for="(item, index) in renderList" :key="index">
      <div class="button-item">
        <div
          :class="[
            'button',
            nowVal === item.key && 'active-item',
            disabled && 'disabled-item',
          ]"
          @click="choosenOption(item)"
        >
          {{ item.value }}
        </div>
        <slot :name="`tips-${index + 1}`" />
      </div>
      <slot :name="`content-${index + 1}`" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    alignType: {
      required: false,
      default: "row",
    },
    renderList: {
      required: true,
    },
    parentKey: {
      required: true,
    },
    echoContent: {
      required: true,
    },
    disabled: {
      requried: false,
    },
  },
  data() {
    return {
      nowVal: "",
      returnObj: {},
    };
  },
  computed: {
    ...mapState({
      language: (state) => state.language,
    }),
  },
  watch: {
    echoContent: {
      handler: function(newval) {
        this.nowVal = newval;
      },
      deep: true,
    },
  },
  mounted() {
    this.nowVal = this.echoContent;
  },
  methods: {
    choosenOption(val) {
      if (this.disabled) return;
      this.nowVal = val.key;
      this.$set(this.returnObj, this.parentKey, val.key);
      this.$emit("buttonChange", this.returnObj, this.parentKey, val.key);
    },
  },
};
</script>

<style lang="scss" scoped>
.button-content,
.button-content-column {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .button-wrap {
    margin-right: 20px;
  }

  .button-item {
    display: flex;
    justify-content: center;
    align-items: center;

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      min-width: 96px;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      font-size: 14px;
      line-height: 20px;
      color: #333;
      cursor: pointer;
      height: 36px;
    }

    &:last-child {
      .button {
        margin-right: 0px;
      }
    }
  }

  .disabled-item {
    background-color: #e5e5e5;
    color: #999;
  }

  .active-item {
    background-color: #fec80e;
    border-color: #fec80e;
    color: #333;
  }
}

.button-content-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .button-wrap {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
